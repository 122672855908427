// import { lazy } from 'react';

import { IRoute } from '../../types/route-type';
import AnalyticsPage from '../../pages/AnalyticsPage';
// const AnalyticsPage = lazy(() => import('../../pages/AnalyticsPage'));

export const ANALYTICS: IRoute = {
  id: 'analytics',
  path: '/analytics',
  component: AnalyticsPage
};
