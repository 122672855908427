import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';

import { deleteAccessToken, getAccessToken } from '../helpers/auth-helper';
import { showMessage } from '../helpers/message-helper';
import { PRIVATE_ROUTES, PUBLIC_ROUTES } from '../routes/routes-config';
import { history } from '../store';

const END_POINT = process.env.REACT_APP_BASE_URL;

const api = axios.create({
  baseURL: END_POINT,
  headers: {
    'Content-Type': 'application/json',
    'device-type': 0
  }
});

api.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError) => {
    const errorResponse = error?.response;
    const msg = errorResponse?.data?.message;
    switch (errorResponse?.status) {
      case 422:
      case 400:
      case 429:
        showMessage({ type: 'error', msg });
        break;
      case 404:
        history.push(PRIVATE_ROUTES.NOTFOUND.path);
        showMessage({ type: 'error', msg });
        break;
      case 401:
        deleteAccessToken();
        history.push(PUBLIC_ROUTES.SIGN_IN.path);
        setTimeout(() => {
          showMessage({ type: 'error', msg });
        }, 0);
        break;
    }
    return Promise.reject(errorResponse?.data);
  }
);

api.interceptors.request.use((request: AxiosRequestConfig) => {
  request.headers['Authorization'] = `Bearer ${getAccessToken()}`;
  return request;
});

export default api;
