import { FC, memo, ReactNode } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '../store';
import loadingIconGif from '../assets/icons/loading-icon.gif';
import searchNoResultPng from '../assets/images/search-no-result.png';
import { useTranslation } from 'react-i18next';

interface PageContentProps {
  children?: ReactNode;
  className?: string;
  addCondShowNoResult?: boolean;
}

const PageContent: FC<PageContentProps> = ({
  children,
  className,
  addCondShowNoResult = false
}) => {
  const { t: translate } = useTranslation();
  const { isLoading, isSearchNoResult } = useSelector(
    (state: RootState) => state.app
  );

  return (
    <div className={`page-content ${className || ''}`.trim()}>
      {isLoading ? (
        <div className="loading-content">
          <img className="loading-icon" src={loadingIconGif} alt="Loading" />
        </div>
      ) : (
        <>
          {isSearchNoResult && !addCondShowNoResult ? (
            <div className="search-no-result">
              <img width="150" src={searchNoResultPng} alt="Search no result" />
              <p className="text-primary text-3xl mt-3">
                {translate('general.searchNoResult')}
              </p>
            </div>
          ) : (
            children
          )}
        </>
      )}
    </div>
  );
};

export default memo(PageContent);
