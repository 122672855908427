import { PRIVATE_ROUTES } from '../routes/routes-config';
import { ReactComponent as ProfileSvg } from '../assets/icons/profile.svg';
import { ReactComponent as LockSvg } from '../assets/icons/lock.svg';
import { ReactComponent as ChartGreenSvg } from '../assets/icons/chart-green.svg';
import { ReactComponent as LogoutSvg } from '../assets/icons/logout.svg';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const PROFILE_MENU_DATA = (translate: any) => {
  return [
    {
      icon: ProfileSvg,
      text: translate('general.profileMenu.profile'),
      to: PRIVATE_ROUTES.PROFILE.path
    },
    {
      icon: LockSvg,
      text: translate('general.profileMenu.changePassword'),
      to: PRIVATE_ROUTES.CHANGE_PASSWORD.path
    },
    {
      icon: ChartGreenSvg,
      text: translate('general.profileMenu.targetSetting'),
      to: PRIVATE_ROUTES.TARGET_SETTING.path
    },
    {
      icon: LogoutSvg,
      text: translate('general.profileMenu.logout'),
      logoutAction: true
    }
  ];
};
