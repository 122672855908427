import { FC, memo, useEffect } from 'react';
import cocomedLogoPng from '../assets/images/cocomed-logo.png';

const Policy: FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="policy-wrapper">
      <div className="policy-header">
        <img width="100" src={cocomedLogoPng} alt="Cocomed logo" />
      </div>
      <div className="policy-content">
        <ol>
          <li>
            <strong>CHÍNH SÁCH BẢO MẬT THÔNG TIN KHÁCH HÀNG:</strong>
            <p>
              Công Ty TNHH giáo dục y khoa và chăm sóc sức khỏe CocoMed cam kết
              bảo mật an toàn tuyệt đối với thông tin cá nhân của khách hàng
              theo chính sách bảo vệ thông tin của doanh nghiệp và các quy định
              của pháp luật Việt Nam. Cụ thể:
            </p>
            <ol>
              <li>
                <strong>Mục đích thu thập thông tin cá nhân</strong>
                <ul>
                  <li>
                    Việc thu thập dữ liệu trên website education.cocomed.com.vn
                    bao gồm: email, điện thoại, tên đăng nhập, mật khẩu đăng
                    nhập, địa chỉ khách hàng (thành viên). Đây là các thông tin
                    mà education.cocomed.com.vn cần thành viên cung cấp bắt buộc
                    khi đăng ký sử dụng dịch vụ và để education.cocomed.com.vn
                    liên hệ xác nhận khi khách hàng đăng ký sử dụng dịch vụ trên
                    website nhằm đảm bảo quyền lợi cho cho người tiêu dùng.
                  </li>
                  <li>
                    Các thành viên sẽ tự chịu trách nhiệm về bảo mật và lưu giữ
                    mọi hoạt động sử dụng dịch vụ dưới tên đăng ký, mật khẩu và
                    hộp thư điện tử của mình. Ngoài ra, thành viên có trách
                    nhiệm thông báo kịp thời cho education.cocomed.com.vn về
                    những hành vi sử dụng trái phép, lạm dụng, vi phạm bảo mật,
                    lưu giữ tên đăng ký và mật khẩu của bên thứ ba để có biện
                    pháp giải quyết phù hợp.
                  </li>
                </ul>
              </li>
              <li>
                <strong>Phạm vi thu thập thông tin</strong>
                <p>
                  Website education.cocomed.com.vn sử dụng thông tin thành viên
                  cung cấp để:
                </p>
                <ul>
                  <li>Cung cấp các dịch vụ đến Thành viên.</li>
                  <li>
                    Gửi các thông báo về các hoạt động trao đổi thông tin giữa
                    thành viên và education.cocomed.com.vn.
                  </li>
                  <li>
                    Ngăn ngừa các hoạt động phá hủy tài khoản người dùng của
                    thành viên hoặc các hoạt động giả mạo Thành viên.
                  </li>
                  <li>
                    Liên lạc và giải quyết với thành viên trong những trường hợp
                    đặc biệt.
                  </li>
                  <li>
                    Không sử dụng thông tin cá nhân của thành viên ngoài mục
                    đích xác nhận và liên hệ có liên quan đến giao dịch tại
                    education.cocomed.com.vn.
                  </li>
                  <li>
                    Trong trường hợp có yêu cầu của pháp luật:
                    education.cocomed.com.vn có trách nhiệm hợp tác cung cấp
                    thông tin cá nhân thành viên khi có yêu cầu từ cơ quan tư
                    pháp bao gồm: Viện kiểm sát, tòa án, cơ quan công an điều
                    tra liên quan đến hành vi vi phạm pháp luật nào đó của khách
                    hàng. Ngoài ra, không ai có quyền xâm phạm vào thông tin cá
                    nhân của thành viên.
                  </li>
                </ul>
              </li>
              <li>
                <strong>Thời gian lưu trữ thông tin</strong>
                <p>
                  Dữ liệu cá nhân của Thành viên sẽ được lưu trữ cho đến khi có
                  yêu cầu hủy bỏ hoặc tự thành viên đăng nhập và thực hiện hủy
                  bỏ. Còn lại trong mọi trường hợp thông tin cá nhân thành viên
                  sẽ được bảo mật trên máy chủ của education.cocomed.com.vn.
                </p>
              </li>
              <li>
                <strong>
                  Những người hoặc tổ chức có thể được tiếp cận với thông tin đó
                </strong>
                <ul>
                  <li>
                    Các đối tác là bên cung cấp dịch vụ cho chúng tôi liên quan
                    đến thực hiện đơn hàng và chỉ giới hạn trong phạm vi thông
                    tin cần thiết cũng như áp dụng các quy định đảm bảo an ninh,
                    bảo mật các thông tin cá nhân.
                  </li>
                  <li>
                    Chúng tôi sử dụng dịch vụ từ một nhà cung cấp dịch vụ là bên
                    thứ ba để thực hiện một số hoạt động liên quan đến website
                    education.cocomed.com.vn. Khi đó, bên thứ ba có thể truy cập
                    hoặc xử lý các thông tin cá nhân trong quá trình cung cấp
                    các dịch vụ đó. Chúng tôi yêu cầu các bên thứ ba này tuân
                    thủ mọi luật lệ về bảo vệ thông tin cá nhân liên quan và các
                    yêu cầu về an ninh liên quan đến thông tin cá nhân.
                  </li>
                  <li>
                    Các chương trình có tính liên kết, đồng thực hiện, thuê
                    ngoài cho các mục đích được nêu tại Mục 1 và luôn áp dụng
                    các yêu cầu bảo mật thông tin cá nhân.
                  </li>
                  <li>
                    Yêu cầu pháp lý: Chúng tôi có thể tiết lộ các thông tin cá
                    nhân nếu điều đó do luật pháp yêu cầu và việc tiết lộ như
                    vậy là cần thiết một cách hợp lý để tuân thủ các quy trình
                    pháp lý.
                  </li>
                  <li>
                    Chuyển giao kinh doanh (nếu có): trong trường hợp sáp nhập,
                    hợp nhất toàn bộ hoặc một phần với công ty khác, người mua
                    sẽ có quyền truy cập thông tin được chúng tôi lưu trữ, duy
                    trì trong đó bao gồm cả thông tin cá nhân.
                  </li>
                </ul>
              </li>
              <li>
                <strong>
                  Địa chỉ của đơn vị thu thập và quản lý thông tin
                </strong>
                <p>
                  Tên doanh nghiệp: CÔNG TY TNHH GIÁO DỤC Y KHOA VÀ CHĂM SÓC SỨC
                  KHỎE COCOMED
                </p>
                <ul>
                  <li>
                    Trụ sở chính: 43 Hoàng Sa, phường Đa Kao, quận 1, Thành phố
                    Hồ Chí Minh
                  </li>
                  <li>Điện thoại: 0902971409</li>
                  <li>Email: cocomedgroup@gmail.com</li>
                </ul>
              </li>
              <li>
                <strong>
                  Phương thức và công cụ để người dùng tiếp cận và chỉnh sửa dữ
                  liệu:
                </strong>
                <ul>
                  <li>
                    Thành viên có quyền tự kiểm tra, cập nhật, điều chỉnh hoặc
                    hủy bỏ thông tin cá nhân của mình bằng cách đăng nhập vào
                    tài khoản và chỉnh sửa thông tin cá nhân hoặc yêu cầu ban
                    quản trị website education.cocomed.com.vn thực hiện việc
                    này.
                  </li>
                  <li>
                    Thành viên có quyền gửi khiếu nại về việc lộ thông tin cá
                    nhân cho bên thứ 3 đến Ban quản trị của website
                    education.cocomed.com.vn. Khi tiếp nhận những phản hồi này,
                    education.cocomed.com.vn sẽ xác nhận lại thông tin, phải có
                    trách nhiệm trả lời lý do và hướng dẫn thành viên khôi phục
                    và bảo mật lại thông tin.
                  </li>
                </ul>
              </li>
            </ol>
          </li>
          <li>
            <strong>CHÍNH SÁCH THANH TOÁN:</strong>
            <ul>
              <li>
                Thanh toán bằng tiền mặt: 43 Hoàng Sa, phường Đa Kao, quận 1,
                Thành phố Hồ Chí Minh
              </li>
              <li>
                Thanh toán bằng hình thức chuyển khoản:
                <p>Ngân hàng Techcombank chi nhánh Lê Quang Định</p>
                <p>Stk: 1903 666 8938 014</p>
              </li>
            </ul>
          </li>
          <li>
            <strong>CHÍNH SÁCH BẢO HÀNH VÀ ĐỔI TRẢ DỊCH VỤ</strong>
            <p>
              CocoMed đảm bảo sản phẩm được bán tại CocoMed là sản phẩm mới và
              100% chính hãng. Trong trường hợp hiếm hoi sản phẩm quý khách nhận
              được có khiếm khuyết, hư hỏng hoặc không như mô tả, CocoMed cam
              kết bảo vệ khách hàng bằng chính sách đổi trả và bảo hành.
            </p>
            <ol>
              <li>
                Trường hợp lỗi do nhà sản xuất:
                <ul>
                  <li>Trong 7 ngày: Đổi mới sản phẩm hoặc trả không thu phí</li>
                  <li>Trong 30 ngày: Bảo hành sản phẩm</li>
                </ul>
              </li>
              <li>
                Trường hợp lỗi do người sử dụng: Bảo hành hoặc sửa chữa có thu
                phí theo quy định của nhà cung cấp.
              </li>
            </ol>
          </li>
        </ol>
      </div>
    </div>
  );
};

export default memo(Policy);
