import { lazy } from 'react';

import { IRoute } from '../../types/route-type';
const TutorialPage = lazy(() => import('../../pages/TutorialPage'));

export const TUTORIAL: IRoute = {
  id: 'tutorial',
  path: '/tutorial',
  component: TutorialPage
};
