import { IMenuItem } from '../types/menu-item-type';
import { PRIVATE_ROUTES } from '../routes/routes-config';
import { ReactComponent as HomeSvg } from '../assets/icons/sidebar/home.svg';
import { ReactComponent as CategorySvg } from '../assets/icons/sidebar/category.svg';
import { ReactComponent as QASvg } from '../assets/icons/sidebar/q&a.svg';
import { ReactComponent as ChartSvg } from '../assets/icons/sidebar/chart.svg';
import { ReactComponent as ChatSvg } from '../assets/icons/sidebar/chat.svg';
import { ReactComponent as TutorialSvg } from '../assets/icons/sidebar/play.svg';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const SIDEBAR_DATA = (translate: any): IMenuItem[] => {
  return [
    {
      icon: HomeSvg,
      text: translate('general.sidebar.home'),
      to: PRIVATE_ROUTES.DASHBOARD.path
    },
    {
      icon: CategorySvg,
      text: translate('general.sidebar.category'),
      to: PRIVATE_ROUTES.CATEGORY.path
    },
    {
      icon: QASvg,
      text: translate('general.sidebar.q&a'),
      to: PRIVATE_ROUTES.QA.path
    },
    {
      icon: ChartSvg,
      text: translate('general.sidebar.analytics'),
      to: PRIVATE_ROUTES.ANALYTICS.path
    },
    {
      icon: ChatSvg,
      text: translate('general.sidebar.feedback'),
      to: PRIVATE_ROUTES.FEEDBACK.path
    },
    {
      icon: TutorialSvg,
      text: translate('general.sidebar.tutorial'),
      to: PRIVATE_ROUTES.TUTORIAL.path
    }
  ];
};
