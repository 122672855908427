import { FC, ReactNode } from 'react';

import cocomedLogoPng from '../assets/images/cocomed-logo.png';
import authenticateNursePng from '../assets/images/authenticate-nurse.png';

interface AuthenLayoutProps {
  children: ReactNode;
}

const AuthenLayout: FC<AuthenLayoutProps> = ({ children }) => {
  return (
    <div className="authenticate-layout d-flex align-center justify-center">
      <img
        width="150"
        className="app-logo"
        src={cocomedLogoPng}
        alt="Cocomed logo"
      />
      <div className="authenticate-form-wrapper">{children}</div>
      <img
        className="nurse-image align-end"
        src={authenticateNursePng}
        alt="Authenticate nurse"
      />
    </div>
  );
};

export default AuthenLayout;
