/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SIDEBAR_DATA } from '../static-data/sidebar-data';
import { PROFILE_MENU_DATA } from '../static-data/profile-menu-data';
import { VALIDATION_MESSAGE } from '../constants/validation';

const DATA_TYPE: any = {
  sidebar: SIDEBAR_DATA,
  profileMenu: PROFILE_MENU_DATA,
  validation: VALIDATION_MESSAGE
};

const useOutsideData = (type: 'sidebar' | 'profileMenu' | 'validation') => {
  const { t: translate } = useTranslation();
  const [data, setData] = useState(DATA_TYPE[type](translate));

  useEffect(() => {
    setData(DATA_TYPE[type](translate));
  }, [translate]);

  return data;
};

export default useOutsideData;
