import api from '../utils/api';
import { formatRoute } from '../helpers/function-helper';
import { END_POINT } from '../constants/app';

const register = async (body: unknown, params = {}) => {
  const response = await api.post(formatRoute(END_POINT.SIGN_UP, params), body);
  return response.data;
};

const login = async (body: unknown, params = {}) => {
  const response = await api.post(formatRoute(END_POINT.SIGN_IN, params), body);
  return response.data;
};

const getUser = async (params = {}) => {
  const response = await api.get(END_POINT.USER_ME, { params });
  return response.data;
};

const verifyUser = async (body: unknown, params = {}) => {
  const response = await api.post(
    formatRoute(END_POINT.VERIFY_CODE, params),
    body
  );
  return response.data;
};

const resendCode = async (body: unknown, params = {}) => {
  const response = await api.post(
    formatRoute(END_POINT.RESEND_CODE, params),
    body
  );
  return response.data;
};

const forgotPassword = async (body: unknown) => {
  const response = await api.post(END_POINT.FORGOT_PASSWORD, body);
  return response.data;
};

const resetPassword = async (body: unknown) => {
  const response = await api.post(END_POINT.RESET_PASSWORD, body);
  return response.data;
};

export {
  register,
  login,
  getUser,
  verifyUser,
  resendCode,
  forgotPassword,
  resetPassword
};
