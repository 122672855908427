// Auth helper for storing and getting token data from local storage
const ACCESS_TOKEN = 'access-token';

const setAccessToken = (token: string) =>
  localStorage.setItem(ACCESS_TOKEN, token);

const getAccessToken = () => localStorage.getItem(ACCESS_TOKEN);

const deleteAccessToken = () => localStorage.removeItem(ACCESS_TOKEN);

const isAuthenticated = () => getAccessToken() !== null;

export { setAccessToken, getAccessToken, deleteAccessToken, isAuthenticated };
