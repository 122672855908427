const CATEGORY_TYPE = Object.freeze({
  MUST_PURCHASE: 'must-purchase'
});

const PURCHASE_TYPE = Object.freeze({
  CATEGORY: 2,
  PACKAGE: 1
});

const APP_LANGUAGE = Object.freeze({
  VI: 'vi',
  EN: 'en'
});

const ANSWER_OPTION: { [key: string]: string } = Object.freeze({
  optionA: 'A',
  optionB: 'B',
  optionC: 'C',
  optionD: 'D'
});

const END_POINT = Object.freeze({
  SIGN_UP: '/register',
  SIGN_IN: '/login',
  USER_ME: '/profile',
  VERIFY_CODE: '/verify',
  RESEND_CODE: '/resend-verify',
  GET_CATEGORY: '/categories',
  GET_PACKAGE: '/packages',
  GET_MY_CATEGORY: '/account-categories',
  GET_APP_VERSION: '/app/version',
  SET_TARGET: '/targets/save',
  RESET_TARGET: '/targets/reset',
  SEND_PAYMENT: '/payment',
  GET_SHIPPING_FEE: '/payment/shipping-fee',
  GET_PAYMENY_COUPON: '/payment/coupon',
  GET_CATEGORY_DETAIL: '/categories/:categoryId',
  GET_PACKAGE_DETAIL: '/packages/:packageId',
  GET_CATEGORY_CARDS: '/account-categories/:categoryId',
  GET_MY_CATEGORY_DETAIL: '/account-categories/:categoryId',
  GET_CATEGORY_QUESTIONS: '/account-categories',
  UPDATE_CARD_LEVEL: '/account-cards/:cardId/level',
  UPDATE_CARD_LEARN: '/account-cards/:cardId/learn',
  SUBMIT_ANSWER: '/account-questions/:questionId/answer',
  CHANGE_PASSWORD: '/change-password',
  GET_PROFILE: '/profile',
  UPDATE_PROFILE: '/profile/update',
  UPDATE_USER_AVATAR: '/avatar',
  UPDATE_VIEW_CARD: '/view-card',
  UPDATE_SHIPPING_ADDRESS: '/shipping-address',
  ANALYTIC: '/analytic',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password'
});

export { CATEGORY_TYPE, APP_LANGUAGE, END_POINT, PURCHASE_TYPE, ANSWER_OPTION };
