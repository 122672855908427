import { FC, memo, ReactNode } from 'react';

interface PageTitleProps {
  title: string;
  children?: ReactNode;
}

const PageTitle: FC<PageTitleProps> = ({ title, children }) => {
  return (
    <div className="page-title">
      <span className="font-bold">{title}</span>
      {children}
    </div>
  );
};

export default memo(PageTitle);
