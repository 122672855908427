import api from '../utils/api';
import { END_POINT } from '../constants/app';

const setTargetApi = async (body: unknown) => {
  const response = await api.post(END_POINT.SET_TARGET, body);
  return response.data;
};

const resetTargetApi = async () => {
  const response = await api.post(END_POINT.RESET_TARGET);
  return response.data;
};

export { setTargetApi, resetTargetApi };
