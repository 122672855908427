const formatRoute = (
  routePath: string,
  params: { [idName: string]: number }
) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Object.entries(params).map((entry: any[]) => {
    routePath = routePath.replace(`:${entry[0]}`, entry[1]);
  });
  return routePath;
};

const formatCurrency = (price: number) => {
  return new Intl.NumberFormat('en-US').format(price);
};

const formatParams = (params: Record<string, unknown>) => {
  Object.keys(params).map((key) => {
    if (!params[key]) {
      delete params[key];
    }
  });
  return params;
};

const getMobileOperatingSystem = (getDeviceType = false) => {
  const userAgent = navigator.userAgent || navigator.vendor;
  if (/android/i.test(userAgent)) {
    return getDeviceType ? 'android' : 'Android';
  }
  if (/iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return getDeviceType ? 'ios' : 'iOS';
  }
  return getDeviceType ? 'web' : '';
};

export { formatRoute, formatCurrency, formatParams, getMobileOperatingSystem };
