import { FC, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import Icon from '@ant-design/icons';
import { useSelector } from 'react-redux';

import { IMenuItem } from '../types/menu-item-type';
import { PRIVATE_ROUTES } from '../routes/routes-config';
import { RootState } from '../store';
import useOutsideData from '../hooks/useOutsideData';

const { Sider } = Layout;

const pathsShowSidebar: string[] = [
  PRIVATE_ROUTES.DASHBOARD.path,
  PRIVATE_ROUTES.CATEGORY.path,
  PRIVATE_ROUTES.QA.path,
  PRIVATE_ROUTES.ANALYTICS.path,
  PRIVATE_ROUTES.FEEDBACK.path,
  PRIVATE_ROUTES.TUTORIAL.path,
  PRIVATE_ROUTES.TARGET_SETTING.path,
  PRIVATE_ROUTES.CHANGE_PASSWORD.path,
  PRIVATE_ROUTES.PROFILE.path
];

const Sidebar: FC = () => {
  const { pathname } = useLocation();
  const sidebarData = useOutsideData('sidebar');
  const { isUnShowSidebar } = useSelector((state: RootState) => state.app);

  const sidebarItems = useMemo(
    () =>
      sidebarData.map((item: IMenuItem) => (
        <Menu.Item
          className="menu-item d-flex align-center"
          key={item.to}
          icon={<Icon className="menu-icon" component={item?.icon} />}
        >
          <Link className="menu-link text-lg font-bold" to={item?.to}>
            {item?.text}
          </Link>
        </Menu.Item>
      )),
    [sidebarData]
  );

  return !pathsShowSidebar.includes(pathname) || isUnShowSidebar ? null : (
    <Sider breakpoint="lg" width={232} className="sidebar-layout">
      <Menu
        mode="vertical"
        className="sidebar-menu"
        defaultSelectedKeys={[PRIVATE_ROUTES.DASHBOARD.path]}
        selectedKeys={[pathname]}
      >
        {sidebarItems}
      </Menu>
    </Sider>
  );
};

export default Sidebar;
