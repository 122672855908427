import update from 'immutability-helper';

/* eslint-disable @typescript-eslint/no-explicit-any */
const QUESTIONS = 'questions';

const setQuestions = (
  categoryId: string,
  questions: { [key: string]: any },
  options: { [key: string]: any } = {}
) => {
  const localQuestions = getQuestions();
  const newLocalQuestions = update(localQuestions, {
    [`${categoryId}`]: {
      $set: { questions, ...options }
    }
  });

  localStorage.setItem(QUESTIONS, JSON.stringify(newLocalQuestions));
};

const setFinishedQuestions = (categoryId: string) => {
  const localQuestions = getQuestions();
  const newLocalQuestions = update(localQuestions, {
    [`${categoryId}`]: {
      isFinished: { $set: true }
    }
  });

  localStorage.setItem(QUESTIONS, JSON.stringify(newLocalQuestions));
};

const getQuestions = () => {
  try {
    return JSON.parse(localStorage.getItem(QUESTIONS) ?? '{}');
  } catch (_) {
    return {};
  }
};

const deleteQuestionsByCategory = (categoryId: string) => {
  const questions = getQuestions();
  delete questions[categoryId];
  localStorage.setItem(QUESTIONS, JSON.stringify(questions));
};

const deleteQuestions = () => localStorage.removeItem(QUESTIONS);

export {
  setQuestions,
  getQuestions,
  deleteQuestionsByCategory,
  deleteQuestions,
  setFinishedQuestions
};
