import { lazy } from 'react';

import { IRoute } from '../../types/route-type';
const NotFoundPage = lazy(() => import('../../pages/ErrorsPage'));

/** Screen: Dashboard Page */
export const NOTFOUND: IRoute = {
  id: 'notfound',
  path: '/not-found',
  component: NotFoundPage
};
