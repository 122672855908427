import { lazy } from 'react';

import { IRoute } from '../../types/route-type';
const PaymentPage = lazy(() => import('../../pages/PaymentPage'));
const PaymentSuccessPage = lazy(() => import('../../pages/PaymentSuccessPage'));
const PaymentFailedPage = lazy(() => import('../../pages/PaymentFailedPage'));
const PaymentOptionsPage = lazy(() => import('../../pages/PaymentOptionsPage'));

const PAYMENT: IRoute = {
  id: '4_payment',
  path: '/payment/:objectId',
  component: PaymentPage
};

const PAYMENT_OPTIONS: IRoute = {
  id: '3_payment-options',
  path: '/payment/:objectId/options',
  component: PaymentOptionsPage
};

const PAYMENT_SUCCESS: IRoute = {
  id: '1_payment-success',
  path: '/payment/success',
  component: PaymentSuccessPage
};

const PAYMENT_FAILED: IRoute = {
  id: '2_payment-failed',
  path: '/payment/failed',
  component: PaymentFailedPage
};

export { PAYMENT, PAYMENT_SUCCESS, PAYMENT_FAILED, PAYMENT_OPTIONS };
