import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const appSlice = createSlice({
  name: 'app',
  initialState: {
    textSearch: '',
    isLoading: false,
    isProcessing: false,
    isSearchNoResult: false,
    isUnShowSidebar: false,
    unExpectShowInputSearch: false
  },
  reducers: {
    setLoading: {
      reducer: (state, action: PayloadAction<{ isLoading: boolean }>) => {
        state.isLoading = action.payload.isLoading;
      },
      prepare: (isLoading: boolean) => ({ payload: { isLoading } })
    },
    setProcessing: {
      reducer: (state, action: PayloadAction<{ isProcessing: boolean }>) => {
        state.isProcessing = action.payload.isProcessing;
      },
      prepare: (isProcessing: boolean) => ({ payload: { isProcessing } })
    },
    setSearchNoResult: {
      reducer: (
        state,
        action: PayloadAction<{ isSearchNoResult: boolean }>
      ) => {
        state.isSearchNoResult = action.payload.isSearchNoResult;
      },
      prepare: (isSearchNoResult: boolean) => ({
        payload: { isSearchNoResult }
      })
    },
    setUnExpectShowInputSearch: {
      reducer: (
        state,
        action: PayloadAction<{ unExpectShowInputSearch: boolean }>
      ) => {
        state.unExpectShowInputSearch = action.payload.unExpectShowInputSearch;
      },
      prepare: (unExpectShowInputSearch: boolean) => ({
        payload: { unExpectShowInputSearch }
      })
    },
    setUnShowSidebar: {
      reducer: (state, action: PayloadAction<{ isUnShowSidebar: boolean }>) => {
        state.isUnShowSidebar = action.payload.isUnShowSidebar;
      },
      prepare: (isUnShowSidebar: boolean) => ({
        payload: { isUnShowSidebar }
      })
    },
    setTextSearch: (state, action: PayloadAction<{ textSearch: string }>) => {
      state.textSearch = action.payload.textSearch;
    },
    clearTextSearch: (state) => {
      state.textSearch = '';
    }
  }
});

export const {
  setLoading,
  setTextSearch,
  setProcessing,
  clearTextSearch,
  setUnShowSidebar,
  setSearchNoResult,
  setUnExpectShowInputSearch
} = appSlice.actions;
export default appSlice.reducer;
