import { message } from 'antd';
import Icon from '@ant-design/icons';

import { ReactComponent as TickCircleSvg } from '../assets/icons/tick-circle.svg';
import { ReactComponent as XtickCircleSvg } from '../assets/icons/x-tick-circle.svg';
import { IMessageConfig } from '../types/message-type';

const showMessage = (config: IMessageConfig) => {
  message[config?.type]({
    className: `${config?.type}-message text-blue`,
    content: (
      <span className="text-message text-lg">
        {config?.msg || (config?.type === 'success' ? 'Success' : 'Fail')}
      </span>
    ),
    icon: (
      <Icon
        component={config?.type === 'success' ? TickCircleSvg : XtickCircleSvg}
        style={{ fontSize: '24px' }}
      />
    ),
    duration: config?.duration
  });
};

export { showMessage };
