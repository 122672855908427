/* eslint-disable @typescript-eslint/no-explicit-any */
const VALIDATION = Object.freeze({
  // EMAIL: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  // PASSWORD_PATTERN: /(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?!.*[~`!@#$%^&*()_+\-=[\]{}|;':",.\/<>?])(?<!.\*[~`!@#$%^&*()_+\-=[\]{}|;':",.\/<>?])[\da-zA-Z ]{8,30}/,
  PASSWORD_PATTERN:
    /(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?!.*[~`!@#$%^&*()_+\-=[\]{}|;':",./<>?])[\da-zA-Z ]{8,30}/,
  VERIFY_CODE_PATTERN: /^\d{6}$/
});

// const VALIDATION_MESSAGE = Object.freeze({
//   REQUIRED: 'This field is required.',
//   NAME_REQUIRED: 'Please enter your name.',
//   GENDER_REQUIRED: 'Please tell us your gender.',
//   BIRTHDAY_REQUIRED: 'Please tell us your birthday.',
//   UNIVERSITY_REQUIRED: 'Please tell us your university.',
//   START_DATE_REQUIRED: 'Please select your start date.',
//   PHONE_NUMBER_REQUIRED: 'Please enter your phone number.',
//   DETAIL_ADDRESS_REQUIRED: 'Please enter your detail address.',
//   NUMBER_OF_CARD_REQUIRED: 'Please select your number of cards.',
//   EMAIL_FORMAT: 'Please enter a correct email address.',
//   PASSWORD_FORMAT:
//     'Password must contain at least eight characters, one uppercase, lowercase and digit.',
//   CONFIRM_PASSWORD_MATCH: 'Please enter the same characters as the password.',
//   VERIFYCODE_FORMAT: 'Verification code must contain at least six digits.',
//   CARD_NUMBER_LEN: 'Card number must be exactly 16 numbers.',
//   CVV_NUMBER_LEN: 'CVV must be exactly 3 numbers.',
//   NAME_MAX_LEN: 'Please enter name within 100 characters.',
//   DETAIL_ADDRESS_MAX_LEN: 'Please enter details address within 100 characters.',
//   PHONE_NUMBER_LEN: 'Please enter the phone number from 10 to 11 numbers.',
//   PHONE_NUMBER_FORMAT: 'Phone Number must start from 0.'
// });

const VALIDATION_MESSAGE = (translate: any) => ({
  REQUIRED: translate('validationMessage.required'),
  NAME_REQUIRED: translate('validationMessage.nameRequired'),
  GENDER_REQUIRED: translate('validationMessage.genderRequired'),
  BIRTHDAY_REQUIRED: translate('validationMessage.birthdayRequired'),
  UNIVERSITY_REQUIRED: translate('validationMessage.universityRequired'),
  START_DATE_REQUIRED: translate('validationMessage.startdateRequired'),
  PHONE_NUMBER_REQUIRED: translate('validationMessage.phoneNumberRequired'),
  DETAIL_ADDRESS_REQUIRED: translate('validationMessage.detailAddressRequired'),
  NUMBER_OF_CARD_REQUIRED: translate('validationMessage.numberOfCardRequired'),
  EMAIL_FORMAT: translate('validationMessage.emailFormat'),
  PASSWORD_FORMAT: translate('validationMessage.passwordFormat'),
  CONFIRM_PASSWORD_MATCH: translate('validationMessage.confirmPasswordMatch'),
  VERIFYCODE_FORMAT: translate('validationMessage.verifyCodeFormat'),
  CARD_NUMBER_LEN: translate('validationMessage.cardNumberLen'),
  CVV_NUMBER_LEN: translate('validationMessage.cvvNumberLen'),
  NAME_MAX_LEN: translate('validationMessage.nameMaxLen'),
  DETAIL_ADDRESS_MAX_LEN: translate('validationMessage.detailAddressMaxLen'),
  PHONE_NUMBER_LEN: translate('validationMessage.phoneNumberLen'),
  PHONE_NUMBER_FORMAT: translate('validationMessage.phoneNumberFormar')
});

export { VALIDATION, VALIDATION_MESSAGE };
