import { FC, ReactNode } from 'react';
import { Layout } from 'antd';

import Sidebar from './Sidebar';
import Header from './Header';
const { Content } = Layout;

interface FeatureLayoutProps {
  children: ReactNode;
}

const FeatureLayout: FC<FeatureLayoutProps> = ({ children }) => {
  return (
    <Layout style={{ height: '100vh' }} className="feature-layout">
      <Header />
      <Layout className="bg-white">
        <Sidebar />
        <Content className="content-layout">{children}</Content>
      </Layout>
    </Layout>
  );
};

export default FeatureLayout;
