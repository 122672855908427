import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import enI18n from '../i18n/en.json';
import viI18n from '../i18n/vi.json';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    resources: {
      en: {
        translation: enI18n
      },
      vi: {
        translation: viI18n
      }
    },
    lng: localStorage.getItem('cocomedLng') || 'en', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    fallbackLng: 'en',
    supportedLngs: ['en', 'vi'],
    detection: {
      order: ['localStorage', 'querystring'],
      // keys or params to lookup language from
      lookupLocalStorage: 'cocomedLng',
      // cache user language on
      caches: ['localStorage'],
      excludeCacheFor: ['cimode'] // languages to not persist (cookie, localStorage)
    },
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
