import { lazy } from 'react';

import { IRoute } from '../../types/route-type';
const ChangePasswordPage = lazy(() => import('../../pages/ChangePasswordPage'));
const ProfilePage = lazy(() => import('../../pages/ProfilePage'));

const CHANGE_PASSWORD: IRoute = {
  id: 'change-password',
  path: '/change-password',
  component: ChangePasswordPage
};

const PROFILE: IRoute = {
  id: 'profile',
  path: '/profile',
  component: ProfilePage
};

export { CHANGE_PASSWORD, PROFILE };
