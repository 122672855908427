/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, memo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Area } from '@ant-design/charts';
import { Button, Progress } from 'antd';
import update from 'immutability-helper';
import moment, { Moment } from 'moment';
import { useTranslation } from 'react-i18next'; // without this line it didn't work

// APIs
import { analytic } from '../apis/analytic-api';
import PageTitle from '../modules/PageTitle';
import PageContent from '../modules/PageContent';
import analyticNoData from '../assets/images/analytic-no_data.png';
import { ReactComponent as ArrowRight } from '../assets/icons/arrow-right.svg';
import { ReactComponent as ArrowLeft } from '../assets/icons/arrow-left.svg';
import { ReactComponent as Clock } from '../assets/icons/clock.svg';
import { AnalyticFilter } from '../constants/enum';
import { setLoading, setProcessing } from '../reducers/app-slice';
import { RootState } from '../store/index';

interface ICardsAnalytic {
  learned: number;
  total: number;
}

const chartConfig = {
  xField: 'key',
  yField: 'Cards',
  yAxis: {
    label: {
      style: {
        fontSize: 14,
        fontWeight: 700
      }
    }
  },
  xAxis: {
    label: {
      style: {
        fontSize: 12,
        fontWeight: 500,
        textTransform: 'capitalize'
      }
    }
  },
  line: {
    color: '#103F37',
    size: 0.75
  },
  point: {
    size: 4,
    shape: 'circle',
    color: '#103F37'
  },
  areaStyle: {
    fillOpacity: 1,
    fill: 'l(270) 0:rgba(16, 63, 55, 0.1) 1:rgba(16, 63, 55, 0.4)'
  },
  color: '#103F37'
};

const chartConfigNoData = {
  xField: 'key',
  yField: 'Cards',
  yAxis: {
    label: {
      style: {
        fontSize: 14,
        fontWeight: 700
      }
    }
  },
  xAxis: {
    label: {
      style: {
        fontSize: 12,
        fontWeight: 500,
        textTransform: 'capitalize'
      }
    }
  },
  line: {
    color: 'transparent',
    size: 0.75
  },
  areaStyle: {
    fillOpacity: 0,
    fill: 'l(270) 0:rgba(16, 63, 55, 0.1) 1:rgba(16, 63, 55, 0.4)'
  },
  color: 'transparent'
};

const AnalyticsPage: FC = () => {
  const [isInit, setIsInit] = useState<boolean>(false);
  const [cardsAnalytic, setCardsAnalytic] = useState<ICardsAnalytic>({
    learned: 0,
    total: 0
  });
  const [dataAnalytic, setDataAnalytic] = useState<any[]>([]);
  const [selectedDate, setSelectedDate] = useState<Moment>(moment().clone());
  const [selectedDateText, setSelectedDateText] = useState<string>('');
  const [filterType, setFilterType] = useState<AnalyticFilter>(
    AnalyticFilter.WEEK
  );
  const [isToday, setIsToday] = useState<boolean>(true);
  const [isNoData, setIsNoData] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();
  const { isProcessing } = useSelector((state: RootState) => state.app);

  const parseDataAnalytic = (
    type: AnalyticFilter,
    data: any[],
    currentDate: Moment
  ) => {
    let parseData: any[] = [];
    if (type == AnalyticFilter.YEAR) {
      const monthHasData: any[] = [];
      for (const element of data) {
        const month = parseInt(element.time.split('_')[1]) - 1;
        monthHasData.push(month);
        const newKey = moment().clone().month(month).format('MMM');
        parseData.push({ key: newKey, Cards: element.value });
      }
      if (parseData.length < 12) {
        const monthNoData: any[] = [];
        Array.apply(0, Array(12 - parseData.length)).forEach((_, i) =>
          monthNoData.push({
            key: currentDate
              .clone()
              .month(i + parseData.length)
              .format('MMM'),
            Cards: 0
          })
        );
        parseData.push(...monthNoData);
      }
    } else if (type == AnalyticFilter.MONTH) {
      for (const element of data) {
        const newKey = element.time.split('_')[1];
        parseData = update(parseData, {
          $push: [{ key: newKey, Cards: element.value }]
        });
      }
      if (parseData.length < currentDate.daysInMonth()) {
        const dayNoData: any[] = [];
        Array.apply(
          0,
          Array(currentDate.daysInMonth() - parseData.length)
        ).forEach((_, i) =>
          dayNoData.push({
            key:
              i + parseData.length + 1 >= 10
                ? `${i + parseData.length + 1}`
                : `0${i + parseData.length + 1}`,
            Cards: 0
          })
        );
        parseData.push(...dayNoData);
      }
    } else {
      for (const element of data) {
        parseData = update(parseData, {
          $push: [
            {
              key: currentDate.clone().isoWeekday(element.time).format('ddd'),
              Cards: element.value
            }
          ]
        });
      }
      if (parseData.length < 7) {
        const weekNoData: any[] = [];
        Array.apply(0, Array(7 - parseData.length)).forEach((_, i) =>
          weekNoData.push({
            key: currentDate
              .clone()
              .isoWeekday(i + parseData.length + 1)
              .format('ddd'),
            Cards: 0
          })
        );
        parseData.push(...weekNoData);
      }
    }
    return parseData;
  };

  const onFilter = async (
    type: AnalyticFilter,
    isPrevious?: boolean,
    isCurrent?: boolean
  ) => {
    if (isProcessing) {
      return;
    }
    if (cardsAnalytic.total === 0) {
      setFilterType(type);
      return;
    }
    let _selectedDate = moment().clone();
    if (filterType !== type) {
      setFilterType(type);
      setIsToday(true);
    } else {
      if (isPrevious === undefined && isCurrent === undefined) {
        return;
      }
      if (isCurrent) {
        _selectedDate = moment().clone();
        setIsToday(true);
      }
      if (isPrevious === true) {
        _selectedDate = selectedDate.clone().subtract(1, type);
        setIsToday(false);
      } else if (isPrevious === false) {
        _selectedDate = selectedDate.clone().add(1, type);
      }
    }
    setSelectedDate(_selectedDate);
  };

  useEffect(() => {
    let isUnmounted = false;
    const fetchData = async () => {
      if (isInit) {
        dispatch(setProcessing(true));
      } else {
        dispatch(setLoading(true));
      }
      try {
        const startDate = selectedDate
          .clone()
          .startOf(filterType === AnalyticFilter.WEEK ? 'isoWeek' : filterType);
        const endDate = selectedDate
          .clone()
          .endOf(filterType === AnalyticFilter.WEEK ? 'isoWeek' : filterType);

        const response = await Promise.all([
          analytic({
            type: filterType,
            start_date: startDate.format('YYYY-MM-DD'),
            end_date: endDate.isAfter(moment())
              ? moment().clone().format('YYYY-MM-DD')
              : endDate.format('YYYY-MM-DD')
          }),
          isInit ? Promise.resolve() : analytic({ type: 'learned' })
        ]);
        if (!isUnmounted) {
          if (endDate.isAfter(moment())) {
            setIsToday(true);
          }
          if (response[0]) {
            const data = parseDataAnalytic(
              filterType,
              response[0].data,
              startDate
            );
            const isNoData = data.every((v) => v.Cards == 0);
            if (isNoData) {
              data[0] = { key: data[0].key, Cards: 50 };
            }
            setIsNoData(isNoData);
            setDataAnalytic(data);
            switch (filterType) {
              case AnalyticFilter.YEAR:
                setSelectedDateText(selectedDate.format('YYYY'));
                break;
              case AnalyticFilter.MONTH:
                setSelectedDateText(selectedDate.format('MM-YYYY'));
                break;
              case AnalyticFilter.WEEK:
                setSelectedDateText(
                  `${startDate.format('DD-MM-YYYY')} ~ ${endDate.format(
                    'DD-MM-YYYY'
                  )}`
                );
                break;
              default:
                setSelectedDateText('');
                break;
            }
          }
          if (response[1]) {
            setCardsAnalytic({
              learned: response[1].learned || 0,
              total: response[1].total || 0
            });
          }
        }
      } finally {
        if (!isUnmounted) {
          if (!isInit) {
            setIsInit(true);
            dispatch(setLoading(false));
          } else {
            dispatch(setProcessing(false));
          }
        }
      }
    };

    fetchData();

    return () => {
      isUnmounted = true;
      dispatch(setLoading(false));
    };
  }, [filterType, selectedDate]);

  return (
    <div className="analytics-page page-wrapper">
      <PageTitle title={translate('analytics.title')} />
      <PageContent className="has-page-title">
        <div className="d-flex analytics-wrapper">
          <div className="chart-wrapper d-flex flex-column justify-start align-center">
            <div className="chart-header d-flex justify-between">
              <span className="chart-title text-default font-bold text-xxl">
                {translate('analytics.history')}
              </span>
              <div className="chart-filter">
                <Button
                  className={`filter-button ${
                    filterType === AnalyticFilter.WEEK
                      ? 'btn-orange'
                      : 'btn-orange-light'
                  }`}
                  onClick={() => onFilter(AnalyticFilter.WEEK)}
                >
                  {translate('analytics.week')}
                </Button>
                <Button
                  className={`filter-button ml-2 ${
                    filterType === AnalyticFilter.MONTH
                      ? 'btn-orange'
                      : 'btn-orange-light'
                  }`}
                  onClick={() => onFilter(AnalyticFilter.MONTH)}
                >
                  {translate('analytics.month')}
                </Button>
                <Button
                  className={`filter-button ml-2 ${
                    filterType === AnalyticFilter.YEAR
                      ? 'btn-orange'
                      : 'btn-orange-light'
                  }`}
                  onClick={() => onFilter(AnalyticFilter.YEAR)}
                >
                  {translate('analytics.year')}
                </Button>
              </div>
            </div>
            <div className="chart-content">
              {isNoData && (
                <div className="no-data-img d-flex flex-column justify-start align-center">
                  <img
                    src={analyticNoData}
                    alt="Analytic no data"
                    style={{ height: '65%' }}
                  />
                  <h1 className="text-xxl text-primary font-bold pt-1">
                    {translate('analytics.noData')}
                  </h1>
                </div>
              )}
              {!isNoData && (
                <Area {...chartConfig} data={dataAnalytic} className="chart" />
              )}
              {isNoData && (
                <Area
                  {...chartConfigNoData}
                  data={dataAnalytic}
                  className="chart"
                />
              )}
            </div>
            <div className="chart-select-year d-centered">
              <Button
                icon={<ArrowLeft />}
                disabled={isProcessing || cardsAnalytic.total === 0}
                style={{ padding: 0 }}
                onClick={() => {
                  if (
                    filterType !== undefined &&
                    cardsAnalytic.total !== 0 &&
                    !isProcessing
                  ) {
                    onFilter(filterType, true);
                  }
                }}
              />
              <span className="chart-year-text text-default font-bold text-xxl">
                {selectedDateText}
              </span>
              <Button
                icon={<ArrowRight />}
                disabled={isProcessing || isToday || cardsAnalytic.total === 0}
                style={{ padding: 0 }}
                onClick={() => {
                  if (
                    filterType !== undefined &&
                    !isToday &&
                    cardsAnalytic.total !== 0 &&
                    !isProcessing
                  ) {
                    onFilter(filterType, false);
                  }
                }}
              />
              <Button
                icon={<Clock />}
                style={{ padding: 0 }}
                className="btn-orange d-centered current-button"
                disabled={
                  !(
                    filterType !== undefined &&
                    !isToday &&
                    cardsAnalytic.total !== 0 &&
                    !isProcessing
                  )
                }
                onClick={() => {
                  onFilter(filterType, undefined, true);
                }}
              />
            </div>
          </div>
          <div className="progress-wrapper d-centered flex-column">
            <span className="progress-title text-xxl font-bold text-blue">
              {translate('analytics.totalLearndCard')}
            </span>
            <Progress
              className="mt-6"
              type="circle"
              percent={
                cardsAnalytic.total === 0
                  ? 0
                  : (cardsAnalytic.learned / cardsAnalytic.total) * 100
              }
              strokeWidth={10}
              strokeColor="#103F37"
              trailColor="#D8F2EF"
              width={270}
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              format={(_, __) => (
                <div className="progress-info">
                  <span className="text-blue progress-info-text font-bold text-xxl">
                    {cardsAnalytic.total === 0
                      ? '00/00'
                      : `${cardsAnalytic.learned}/${cardsAnalytic.total}`}
                  </span>
                  <span className="progress-sub-info-text text-sm">
                    {translate('analytics.learned')} /{' '}
                    {translate('analytics.total')}
                  </span>
                </div>
              )}
            />
          </div>
        </div>
      </PageContent>
    </div>
  );
};

export default memo(AnalyticsPage);
