import { lazy } from 'react';

import { IRoute } from '../../types/route-type';
const DashboardPage = lazy(() => import('../../pages/DashboardPage'));

/** Screen: Dashboard Page */
export const DASHBOARD: IRoute = {
  id: 'home',
  path: '/home',
  component: DashboardPage
};
