import { lazy } from 'react';

import { IRoute } from '../../types/route-type';
const SignInPage = lazy(() => import('../../pages/SignInPage'));
const SignUpPage = lazy(() => import('../../pages/SignUpPage'));
const ForgotPasswordPage = lazy(() => import('../../pages/ForgotPasswordPage'));
const ResetPasswordPage = lazy(() => import('../../pages/ResetPasswordPage'));
const VerificationCodePage = lazy(
  () => import('../../pages/VerificationCodePage')
);

/** Screen: Sign In Page */
const SIGN_IN: IRoute = {
  id: 'sign-in',
  path: '/sign-in',
  component: SignInPage
};

/** Screen: Sign Up Page */
const SIGN_UP: IRoute = {
  id: 'sign-up',
  path: '/sign-up',
  component: SignUpPage
};

/** Screen: Forgot Password Page */
const FORGOT_PASSWORD: IRoute = {
  id: 'forgot-password',
  path: '/forgot-password',
  component: ForgotPasswordPage
};

/** Screen: Reset Password Page */
const RESET_PASSWORD: IRoute = {
  id: 'reset-password',
  path: '/reset-password',
  component: ResetPasswordPage
};

/** Screen: Verification Code Page */
const VERIFICATION_CODE: IRoute = {
  id: 'verify-code',
  path: '/verify-code',
  component: VerificationCodePage
};

export { SIGN_IN, SIGN_UP, FORGOT_PASSWORD, RESET_PASSWORD, VERIFICATION_CODE };
