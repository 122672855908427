import { lazy } from 'react';

import { IRoute } from '../../types/route-type';
const CategoryPage = lazy(() => import('../../pages/CategoryPage'));
const CategoryCardListPage = lazy(
  () => import('../../pages/CategoryCardListPage')
);
const CategoryCardDetailPage = lazy(
  () => import('../../pages/CategoryCardDetailPage')
);

const CATEGORY: IRoute = {
  id: 'categories',
  path: '/categories',
  component: CategoryPage
};

const CATEGORY_QUESTIONS: IRoute = {
  id: 'categories-cards',
  path: '/categories/:categoryId',
  component: CategoryCardListPage
};

const CATEGORY_CARD_DETAIL: IRoute = {
  id: 'categories-card-detail',
  path: '/categories/:categoryId/detail',
  component: CategoryCardDetailPage
};

export { CATEGORY, CATEGORY_QUESTIONS, CATEGORY_CARD_DETAIL };
