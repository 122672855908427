import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router';
// import 'moment/locale/vi';

import './styling/styles.scss';
import './utils/i18n';
import App from './App/App';
import reportWebVitals from './reportWebVitals';
import store, { history } from './store';

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <App />
    </Router>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
