import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history';

import userReducer from '../reducers/user-slice';
import appReducer from '../reducers/app-slice';

const rootReducer = combineReducers({
  app: appReducer,
  user: userReducer
});

const store = configureStore({ reducer: rootReducer });

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const history = createBrowserHistory();
export default store;
