import { lazy } from 'react';

import { IRoute } from '../../types/route-type';
const QuestionAnswerPage = lazy(() => import('../../pages/QuestionAnswerPage'));
const QAQuestionsPage = lazy(() => import('../../pages/QAQuestionsPage'));
const QAQuestionsResultPage = lazy(
  () => import('../../pages/QAQuestionsResultPage')
);
const QAQuestionsFinishedPage = lazy(
  () => import('../../pages/QAQuestionsFinishedPage')
);

const QA: IRoute = {
  id: 'qa',
  path: '/qa',
  component: QuestionAnswerPage
};

const QA_QUESTIONS: IRoute = {
  id: 'qa-questions',
  path: '/qa/:categoryId',
  component: QAQuestionsPage
};

const QA_QUESTIONS_RESULT: IRoute = {
  id: 'qa-questions-result',
  path: '/qa/:categoryId/result',
  component: QAQuestionsResultPage
};

const QA_QUESTIONS_FINISHED: IRoute = {
  id: 'qa-questions-finished',
  path: '/qa/:categoryId/finished',
  component: QAQuestionsFinishedPage
};

export { QA, QA_QUESTIONS_RESULT, QA_QUESTIONS, QA_QUESTIONS_FINISHED };
