import {
  ElementType,
  FC,
  Suspense,
  useEffect,
  useState,
  useCallback,
  useMemo
} from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { values, sortBy } from 'lodash-es';
import { useSelector, useDispatch } from 'react-redux';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';

import AuthenLayout from '../layouts/AuthenLayout';
import FeatureLayout from '../layouts/FeatureLayout';
import ProgressBar from '../modules/ProgressBar';
import AuthFooter from '../modules/AuthFooter';
import { isAuthenticated } from '../helpers/auth-helper';
import { PRIVATE_ROUTES, PUBLIC_ROUTES } from '../routes/routes-config';
import { IRoute } from '../types/route-type';
import { RootState, history } from '../store';
import { getUser } from '../apis/auth-api';
import { storeUser, storeTarget } from '../reducers/user-slice';
import {
  setProcessing,
  clearTextSearch,
  setUnShowSidebar,
  setSearchNoResult,
  setUnExpectShowInputSearch
} from '../reducers/app-slice';
import cocomedLogoGrayPNG from '../assets/images/cocomed-logo-gray.png';
import cocomedLogoPNG from '../assets/images/cocomed-logo.png';
import comingsoonNursePNG from '../assets/images/target-nurse.png';
// import comingsoonPNG from '../assets/images/empty-image.png';

const App: FC = () => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const { pathname } = useLocation();
  const [isFetchingUser, setIsFetchingUser] = useState<boolean>(true);
  const { user } = useSelector((state: RootState) => state.user);
  let isShowMain = false;

  if (
    /\/payment\//.test(pathname) ||
    pathname === PUBLIC_ROUTES.PRIVACY.path ||
    pathname === PUBLIC_ROUTES.SIGN_IN.path ||
    pathname === PUBLIC_ROUTES.SIGN_UP.path ||
    pathname === PUBLIC_ROUTES.FORGOT_PASSWORD.path ||
    pathname === PUBLIC_ROUTES.RESET_PASSWORD.path ||
    pathname === PUBLIC_ROUTES.VERIFICATION_CODE.path ||
    pathname === PRIVATE_ROUTES.CATEGORY.path
  ) {
    isShowMain = true;
  }

  useEffect(() => {
    message.config({ duration: 2, maxCount: 1, top: 100 });
  }, []);

  useEffect(() => {
    return history.listen(() => {
      dispatch(clearTextSearch());
      dispatch(setProcessing(false));
      dispatch(setUnShowSidebar(false));
      dispatch(setSearchNoResult(false));
      dispatch(setUnExpectShowInputSearch(false));
      message.destroy();
    });
  }, [history]);

  useEffect(() => {
    if (isAuthenticated() && !user) {
      setIsFetchingUser(true);
      getUser({ with: 'target' })
        .then((response) => {
          dispatch(storeUser(response));
          i18n.changeLanguage(response?.language || 'en');
          if (response.target) {
            dispatch(storeTarget(response.target));
          }
        })
        .finally(() => setIsFetchingUser(false));
    } else {
      setIsFetchingUser(false);
    }
  }, [user]);

  // render routes
  const renderRoutes = useCallback((routes: { [key: string]: IRoute }) => {
    return sortBy(values(routes), 'id')
      .filter((route: IRoute) => route.component)
      .map((route: IRoute) => (
        <Route
          exact
          key={route.id}
          path={route.path}
          render={(props) => {
            const Component = route.component as ElementType;
            return <Component {...props} />;
          }}
        />
      ));
  }, []);

  const featureRoutes = useMemo(() => renderRoutes(PRIVATE_ROUTES), []);
  const authRoutes = useMemo(() => renderRoutes(PUBLIC_ROUTES), []);
  const isAccessPublicRoute = useMemo(
    () =>
      values(PUBLIC_ROUTES)
        .map((route: IRoute) => route.path)
        .includes(pathname),
    []
  );

  return (
    <>
      <div className={isShowMain ? '' : 'main-page-wrapper'}>
        <ProgressBar />
        {isFetchingUser ? (
          <div className="fetching-data-loading">
            <img width="300" src={cocomedLogoGrayPNG} alt="Logo gray" />
          </div>
        ) : user && isAuthenticated() ? (
          <FeatureLayout>
            <Suspense fallback={null}>
              <Switch>
                <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
                {featureRoutes}
                <Redirect exact from="/" to={PRIVATE_ROUTES.DASHBOARD.path} />
                <Route
                  path="*"
                  render={() => (
                    <Redirect
                      to={
                        isAccessPublicRoute
                          ? PRIVATE_ROUTES.DASHBOARD.path
                          : PRIVATE_ROUTES.NOTFOUND.path
                      }
                    />
                  )}
                />
              </Switch>
            </Suspense>
          </FeatureLayout>
        ) : pathname === PUBLIC_ROUTES.PRIVACY.path ||
          pathname === PUBLIC_ROUTES.POLICY.path ? (
          <Suspense fallback={null}>
            <Switch>{authRoutes}</Switch>
          </Suspense>
        ) : (
          <>
            {/\/payment\//.test(pathname) ? (
              <>
                <ProgressBar />
                <FeatureLayout>
                  <Suspense fallback={null}>
                    <Switch>
                      {authRoutes}
                      <Redirect to={PUBLIC_ROUTES.SIGN_IN.path} />
                    </Switch>
                  </Suspense>
                </FeatureLayout>
              </>
            ) : (
              <div className="authen-wrapper">
                <AuthenLayout>
                  <Suspense fallback={null}>
                    <Switch>
                      {authRoutes}
                      <Redirect to={PUBLIC_ROUTES.SIGN_IN.path} />
                    </Switch>
                  </Suspense>
                </AuthenLayout>
                <AuthFooter />
              </div>
            )}
          </>
        )}
      </div>
      {!isShowMain ? (
        <div className="coming-soon-section">
          <div className="coming-soon-content">
            <div className="logo">
              <img width="200" src={cocomedLogoPNG} alt="Logo" />
            </div>
            <div className="coming-nurse">
              {/* <div className="message">
                <img width="150" src={comingsoonPNG} alt="Categories" />
                <p>COMING SOON</p>
              </div> */}
              <img
                width="130"
                className="nurse"
                src={comingsoonNursePNG}
                alt="Coming soon nurse"
              />
            </div>
          </div>
        </div>
      ) : (
        <div />
      )}
    </>
  );
};

export default App;
