import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ITarget } from '../types/target-type';
import { IUser } from '../types/user-type';

const userSlice = createSlice({
  name: 'user',
  initialState: {
    user: null as unknown as IUser,
    target: null as unknown as ITarget
  },
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    storeUser(state, action: PayloadAction<any>) {
      state.user = {
        id: action.payload.id,
        name: action.payload.name,
        email: action.payload.email,
        isVerified: action.payload.is_verified,
        avatarUrl: action.payload.avatar_url,
        workUnit: action.payload.work_unit,
        birthday: action.payload.date_of_birth,
        hasViewedCard: !!action.payload.has_viewed_card
      };
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    updateUserProfile(state, action: PayloadAction<any>) {
      state.user = Object.assign(state.user, action.payload);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    storeTarget(state, action: PayloadAction<any>) {
      const target = {
        target: action.payload.target,
        current: action.payload.current,
        startTime: action.payload.start_time,
        endTime: action.payload.end_time
      };
      state.target = Object.assign(state.target ?? {}, target);
    },
    setTarget(state, action: PayloadAction<Record<string, unknown>>) {
      state.target = Object.assign(state.target, action.payload);
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    clearTarget(state) {
      state.target = null as unknown as ITarget;
    }
  }
});

export const {
  setTarget,
  storeUser,
  storeTarget,
  updateUserProfile,
  clearTarget
} = userSlice.actions;
export default userSlice.reducer;
