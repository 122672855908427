import { FC, memo } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '../store';

const ProgressBar: FC = () => {
  const { isProcessing } = useSelector((state: RootState) => state.app);

  return (
    <>
      {isProcessing ? (
        <div className="progress-bar">
          <span className="bar">
            <span className="progress"></span>
          </span>
        </div>
      ) : null}
    </>
  );
};

export default memo(ProgressBar);
