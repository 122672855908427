import { FC, memo } from 'react';
import Icon from '@ant-design/icons';

import logo from '../assets/images/lp-logo.png';
import boCongThuongPNG from '../assets/images/bo-cong-thuong.png';
import { ReactComponent as FacebookSvg } from '../assets/icons/facebook.svg';
import { ReactComponent as YoutubeSvg } from '../assets/icons/youtube.svg';
import { ReactComponent as SkypeSvg } from '../assets/icons/skype.svg';
import { ReactComponent as InstagramSvg } from '../assets/icons/instagram.svg';

const AuthFooter: FC = () => {
  return (
    <footer className="lp-footer">
      <div className="lp-footer__wrapper">
        <div className="lp-footer__header">
          <img width="113" src={logo} alt="Footer logo" />
          <p className="lp-footer__copyright">© 2021 CocoMed</p>
          <p className="lp-footer__social">Kênh truyền thông</p>
          <div className="lp-footer__social-icons">
            <a
              className="lp-footer__social-icons-item"
              href="https://www.youtube.com/channel/UC_4Giqb19cdc5KHoCpFHC7g"
              target="_blank"
              rel="noreferrer"
            >
              <Icon component={YoutubeSvg} color="white" />
            </a>
            <a
              className="lp-footer__social-icons-item"
              href="https://www.facebook.com/cocomedmecenter1"
              target="_blank"
              rel="noreferrer"
            >
              <Icon component={FacebookSvg} color="white" />
            </a>
            <a
              className="lp-footer__social-icons-item"
              href="https://www.facebook.com/cocomedmecenter1"
              target="_blank"
              rel="noreferrer"
            >
              <Icon component={SkypeSvg} color="white" />
            </a>
            <a
              className="lp-footer__social-icons-item"
              href="https://www.instagram.com/cocomedcenter"
              target="_blank"
              rel="noreferrer"
            >
              <Icon component={InstagramSvg} color="white" />
            </a>
          </div>
        </div>
        <div className="lp-footer__content">
          <div className="lp-footer__about-company">
            <p className="lp-footer__company-name">
              CÔNG TY GIÁO DỤC Y KHOA VÀ CHĂM SÓC SỨC KHỎE COCOMED
            </p>
            <ul className="lp-footer__company-info">
              <li>
                - Giấy CNDKDN 0316596420, đăng kí ngày 19/11/2020, cấp bởi Sở
                KHDT thành phố Hồ Chí Minh
              </li>
              <li>- Địa chỉ: 43 Hoàng Sa, Phường Đa Kao, quận 1, TP.HCM</li>
            </ul>
            <img
              width="170"
              src={boCongThuongPNG}
              alt="Bo cong thuong"
              className="mt-1"
            />
          </div>
        </div>
        <div className="lp-footer__privacy">
          <ul>
            <li className="mb-2">
              <a
                href={`${process.env.REACT_APP_APP_URL}/policy`}
                target="_blank"
                rel="noreferrer"
              >
                Chính sách bảo hành
              </a>
            </li>
            <li className="mb-2">
              <a
                href={`${process.env.REACT_APP_APP_URL}/policy`}
                target="_blank"
                rel="noreferrer"
              >
                Chính sách bảo mật thông tin
              </a>
            </li>
            <li>
              <a
                href={`${process.env.REACT_APP_APP_URL}/policy`}
                target="_blank"
                rel="noreferrer"
              >
                Chính sách thanh toán
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default memo(AuthFooter);
