import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Input, Layout, Avatar, Progress, Button, Popover, List } from 'antd';
import Icon from '@ant-design/icons';
import { Link, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash-es/debounce';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { RootState } from '../store';
import cocomedLogoPng from '../assets/images/cocomed-logo.png';
import defaultAvatarJpg from '../assets/images/default-avatar.jpg';
import { ReactComponent as SearchSvg } from '../assets/icons/search.svg';
import { ReactComponent as ChevronDownSvg } from '../assets/icons/chevron-down.svg';
import { ReactComponent as TargetFinishSvg } from '../assets/icons/target-finish.svg';
import { deleteAccessToken } from '../helpers/auth-helper';
import { deleteQuestions } from '../helpers/question-helper';
import { PUBLIC_ROUTES, PRIVATE_ROUTES } from '../routes/routes-config';
import { storeTarget, clearTarget } from '../reducers/user-slice';
import { ReactComponent as TargetUnfinishedSvg } from '../assets/icons/target-unfinished.svg';
import { resetTargetApi } from '../apis/target-api';
import { showMessage } from '../helpers/message-helper';
import { setTextSearch, setProcessing } from '../reducers/app-slice';
import { getMobileOperatingSystem } from '../helpers/function-helper';
import useOutsideData from '../hooks/useOutsideData';

const { Header: AntdHeader } = Layout;

const Header: FC = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const routeQuery = new URLSearchParams(history.location.search);
  const canLogout = !!+(routeQuery.get('can_logout') || 1);
  const [canLogoutState] = useState(canLogout);
  const cardListRouteMatch = useRouteMatch(
    PRIVATE_ROUTES.CATEGORY_QUESTIONS.path
  );
  const profileMenuData = useOutsideData('profileMenu');
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();
  const [visible, setVisible] = useState<boolean>(false);
  const [textInputSearch, setTextInputSearch] = useState<string>('');
  const { user, target } = useSelector((state: RootState) => state.user);
  const { textSearch, isProcessing, unExpectShowInputSearch } = useSelector(
    (state: RootState) => state.app
  );

  useEffect(() => {
    if (
      getMobileOperatingSystem() &&
      !/\/payment\//.test(pathname) &&
      pathname !== PRIVATE_ROUTES.CATEGORY.path
    ) {
      history.replace(PRIVATE_ROUTES.CATEGORY.path);
    }
  }, [pathname]);

  useEffect(() => {
    if (textSearch !== textInputSearch) {
      setTextInputSearch(textSearch);
    }
  }, [textSearch]);

  const logout = useCallback(() => {
    deleteAccessToken();
    deleteQuestions();
    dispatch(clearTarget());
    history.push(PUBLIC_ROUTES.SIGN_IN.path);
  }, []);

  const onResetTarget = useCallback(async () => {
    try {
      dispatch(setProcessing(true));
      const response = await resetTargetApi();
      showMessage({
        type: 'success',
        msg: translate('targetSetting.resetTargetSuccess')
      });
      dispatch(storeTarget(response));
    } finally {
      dispatch(setProcessing(false));
    }
  }, []);

  const finishTarget = useMemo(
    () => target?.current >= target?.target,
    [target]
  );

  const isExpired = useMemo(
    () => moment().isAfter(moment(target?.endTime)),
    [target]
  );

  const showInputSearch = useMemo(
    () =>
      [
        PRIVATE_ROUTES.CATEGORY.path,
        PRIVATE_ROUTES.DASHBOARD.path,
        cardListRouteMatch?.url || ''
      ].includes(pathname),
    [pathname]
  );

  const dispatchTextSearch = useCallback(
    debounce((textSearch: string) => {
      dispatch(setTextSearch({ textSearch }));
    }, 500),
    []
  );

  const profileContent = useMemo(
    () => (
      <List
        className="profile-menu"
        dataSource={profileMenuData}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        renderItem={(item: any) => (
          <List.Item onClick={() => setVisible(false)}>
            {item?.logoutAction ? (
              <a onClick={logout} className="profile-menu-item">
                <Icon component={item?.icon} className="text-xxl" />
                <span className="text-menu-item ml-2 text-md text-blue font-bold">
                  {item?.text}
                </span>
              </a>
            ) : (
              <Link
                to={item?.to || '/'}
                className="profile-menu-item d-flex align-center desktop-only"
              >
                <Icon component={item?.icon} className="text-xxl" />
                <span className="text-menu-item ml-2 text-blue text-md font-bold">
                  {item?.text}
                </span>
              </Link>
            )}
          </List.Item>
        )}
      />
    ),
    [profileMenuData]
  );

  return (
    <AntdHeader className="header-layout d-flex align-center justify-between">
      <div className="header-left">
        <Link to={`${PRIVATE_ROUTES.DASHBOARD.path}`} className="system-logo">
          <img width="100" src={cocomedLogoPng} alt="Cocomed logo" />
        </Link>
        {showInputSearch && !unExpectShowInputSearch && (
          <Input
            prefix={<Icon component={SearchSvg} className="text-xxl" />}
            className="input-search"
            type="text"
            placeholder={`${translate('actions.search')}...`}
            value={textInputSearch}
            onChange={(event) => {
              const searchValue = event.target.value;
              setTextInputSearch(searchValue);
              dispatchTextSearch(searchValue);
            }}
          />
        )}
      </div>
      <div className="header-right d-flex align-center ml-2">
        {target ? (
          <>
            {isExpired || finishTarget ? (
              <Button
                htmlType="button"
                className="btn-default btn-reset-target"
                onClick={onResetTarget}
                disabled={isProcessing}
              >
                {translate('actions.reset')}
              </Button>
            ) : (
              <p className="text-target-cards text-sm text-default font-bold">
                {translate('general.targetCard')}:
              </p>
            )}
            <div className="target-progress-content ml-2">
              <Progress
                type="line"
                percent={Math.ceil((target.current / target.target) * 100)}
                strokeColor={finishTarget || !isExpired ? '#103F37' : '#ffaf28'}
                trailColor="#fff"
                format={() =>
                  finishTarget ? (
                    <span className="font-bold text-md text-white">
                      <Icon component={TargetFinishSvg} className="text-md" />
                      <span className="ml-1">
                        {translate('general.finished')}
                      </span>
                    </span>
                  ) : isExpired ? (
                    <span className="font-bold text-md">
                      <Icon
                        component={TargetUnfinishedSvg}
                        className="text-md"
                      />
                      <span className="ml-1">
                        {translate('general.notFinished')}
                      </span>
                    </span>
                  ) : (
                    <span className="font-bold text-md">
                      {target?.current}/{target?.target}
                    </span>
                  )
                }
              />
            </div>
          </>
        ) : (
          <div />
        )}
        <Popover
          overlayClassName="profile-popover"
          content={profileContent}
          placement="bottom"
          trigger="click"
          visible={visible}
          onVisibleChange={(visible: boolean) => setVisible(visible)}
        >
          <div
            className={`user-info d-flex align-center ml-4 ${
              getMobileOperatingSystem() && !canLogoutState
                ? 'pointer-none'
                : ''
            }`.trim()}
            onClick={() => setVisible(true)}
          >
            <Avatar
              className="user-avatar"
              shape="square"
              size={44}
              src={user?.avatarUrl || defaultAvatarJpg}
            />
            <p className="user-name font-bold text-blue ml-2">
              {user?.name || 'Unknown User'}
            </p>
            <Icon
              component={ChevronDownSvg}
              className="arrow-down-icon text-sm"
            />
          </div>
        </Popover>
      </div>
    </AntdHeader>
  );
};

export default Header;
