enum CardLevel {
  easy = 1,
  medium = 2,
  hard = 3
}

enum QuestionStatus {
  NotYet = 0,
  Correct = 1,
  Incorrect = 2
}

enum AnalyticFilter {
  YEAR = 'year',
  MONTH = 'month',
  WEEK = 'week'
}

enum ProductType {
  Online = '0',
  Paper = '1',
  Combo = '2'
}

enum PaymentMethod {
  Visa = '3',
  Momo = '2',
  Cash = '4'
}

enum ShippingMethod {
  Giaohangtietkiem = '0',
  Grab = '1'
}

export {
  CardLevel,
  QuestionStatus,
  AnalyticFilter,
  ProductType,
  PaymentMethod,
  ShippingMethod
};
