import { lazy } from 'react';

import { IRoute } from '../../types/route-type';
const TargetSettingPage = lazy(() => import('../../pages/TargetSettingPage'));

export const TARGET_SETTING: IRoute = {
  id: 'target',
  path: '/target-setting',
  component: TargetSettingPage
};
