import { lazy } from 'react';

import { IRoute } from '../../types/route-type';
const FeedbackPage = lazy(() => import('../../pages/FeedbackPage'));

export const FEEDBACK: IRoute = {
  id: 'feedback',
  path: '/feedback',
  component: FeedbackPage
};
