import * as AUTH_ROUTES from './public/authentication-routes';
import * as PRIVACY_ROUTES from './public/privacy-routes';
import * as POLICY_ROUTES from './public/policy-routes';
import * as DASHBOARD from './private/dashboard-routes';
import * as CATEGORY from './private/category-routes';
import * as QUESTION_ANSWER from './private/question-answer-routes';
import * as FEEDBACK from './private/feedback-routes';
import * as TUTORIAL from './private/tutorial-routes';
import * as ANALYTICS from './private/analytics-routes';
import * as TARGET_SETTING from './private/target-routes';
import * as PAYMENT from './private/payment-routes';
import * as ERRORS from './private/errors-routes';
import * as CHANGE_PASSWORD from './private/profile-routes';

const PUBLIC_ROUTES = {
  ...AUTH_ROUTES,
  ...PRIVACY_ROUTES,
  ...PAYMENT,
  ...POLICY_ROUTES
};

const PRIVATE_ROUTES = {
  ...DASHBOARD,
  ...CATEGORY,
  ...QUESTION_ANSWER,
  ...FEEDBACK,
  ...TUTORIAL,
  ...ANALYTICS,
  ...TARGET_SETTING,
  ...PAYMENT,
  ...ERRORS,
  ...CHANGE_PASSWORD
};

export { PUBLIC_ROUTES, PRIVATE_ROUTES };
